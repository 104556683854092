.smloader,
.smloader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.smloader {
  margin: 0 auto;
  top: 40%;
  left: 47%;
  font-size: 10px;
  position: fixed;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#smloadingDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #8888888f;
  z-index: 5300;
}

.smtooltip {
  display: inline;
  position: relative;
}
.smtooltip:after {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #444;
  border-radius: 8px;
  color: #fff;
  content: attr(title);
  margin: -82px auto 0;
  font-size: 16px;
  padding: 13px;
  width: 220px;
}
.smtooltip:before {
  border: solid;
  border-color: #444 transparent;
  border-width: 12px 6px 0 6px;
  content: "";
  left: 45%;
  bottom: 30px;
  position: absolute;
}
.redux-toastr .top-left,
.redux-toastr .top-right,
.redux-toastr .top-center,
.redux-toastr .bottom-left,
.redux-toastr .bottom-right,
.redux-toastr .bottom-center {
  width: 400px;
}
.redux-toastr .toastr .rrt-middle-container {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  width: 80%;
  margin-left: 50px;
}
.redux-toastr .toastr.rrt-error {
  background-color: #e74c3c;
}
.redux-toastr .toastr.rrt-info {
  background-color: #60bb71;
}
.redux-toastr .toastr.rrt-info .rrt-progressbar {
  background-color: #3e914d;
}
.redux-toastr .toastr .rrt-middle-container .rrt-title {
  font-weight: normal;
}
.redux-toastr .toastr .rrt-left-container .rrt-holder {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0%;
  margin-top: 0;
  left: 5px;
  line-height: 50px;
}
.redux-toastr .toastr .rrt-left-container {
  width: 50px;
}
.redux-toastr .toastr .close-toastr span {
  left: 30%;
  right: 0;
  top: 0;
  transform: none;
}
.dzu-dropzone {
  overflow: hidden !important;
}
.dzu-inputLabel {
  display: flex !important;
}
.dzu-previewContainer {
  border-bottom: none;
}
.videoordiv {
  border-bottom: 1px solid #d3d3d3;
  line-height: 0.1em;
  width: 50%;
  margin-top: 35px !important;
  margin-bottom: 20px !important;
}
.videoordiv span {
  background: #f1f7fe;
  padding: 0 10px;
  color: #d3d3d3;
}

.price-main--box {
  width: 100%;
  padding: 30px 25px;
  margin-top: 70px;
}
.pricing-title {
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: 10px;
  line-height: normal;
  color: var(--color-primary);
  font-size: 24px;
  text-align: center;
}
.price-main {
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
  font-family: "Open Sans";
  margin: 0 auto;
}

.price-table {
  width: 100%;
  border-collapse: collapse;
  border: 0 none;
  table-layout: fixed;
}
.price-table tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.price-table tr td {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 8px 24px;
  font-size: 14px;
}
.price-table tr td:first-child {
  border-left: 0 none;
}
.price-table tr td:not(:first-child) {
  text-align: center;
}
.price-table tr:nth-child(even) {
  background-color: #ffffff;
}
.price-table tr:hover {
  background-color: #eeeeee;
}
.price-table .fa-check {
  color: #49c0a0;
}
.price-table .fa-times {
  color: #fe3f33;
}
.sm-disabled {
  cursor: no-drop;
}
.sortByLabel {
  font-size: 14px;
}
/* Highlighted column */
.price-table tr:nth-child(2n) td:nth-child(4) {
  background-color: rgba(216, 214, 227, 0.25);
}
.price-table tr td:nth-child(3) {
  background-color: rgba(216, 214, 227, 0.15);
  padding: 8px 48px;
}
.price-table tr td:nth-child(3) .fa-check,
.price-table tr:nth-child(2n) td:nth-child(3) .fa-check {
  /* color: #ffffff; */
}
/**/

.price-table tr.price-table-head td {
  font-size: 18px;
  font-weight: 800;
  /* font-family: "Montserrat"; */
  text-transform: uppercase;
}
.price-table tr.price-table-head {
  background-color: #49c0a0;
  color: #484848;
}
.price-table td.price {
  color: #484848;
  padding: 16px 24px;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  /* font-family: "Montserrat"; */
}
.price-table td.price a {
  background-color: #49c0a0;
  color: #ffffff;
  padding: 12px 32px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat";
  text-transform: uppercase;
  display: inline-block;
  border-radius: 64px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.price-table td.price a:hover {
  text-decoration: none;
  background-color: #ffffff;
  color: #484848;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}
.price-table td.price-table-popular {
  font-family: "Montserrat";
  border-top: 3px solid #49c0a0;
  color: #49c0a0;
  text-transform: uppercase;
  font-size: 12px;
  padding: 12px 48px;
  font-weight: 700;
}
.price-table .price-blank {
  background-color: #fafafa;
  border: 0 none;
}

.price-table svg {
  width: 90px;
  fill: #49c0a0;
}
.pricing-plans-notes {
  /* color: #fe3f33; */
  margin-top: 5px;
  text-align: right;
}

.plans-stage--icon {
  border-radius: 28px;
  padding: 2px 10px;
  display: inline-block;
  text-align: center;
  line-height: normal;
  font-size: 12px;
  font-weight: 500;
}

.switch-field {
  padding: 40px;
  overflow: hidden;
}

.switch-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  display: inline-block;
  width: 50px;
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 3px 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #484848;
  color: #fff;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}
.text-success-prep {
  color: #050a09 !important;
}

.line-through {
  text-decoration: line-through;
}

.btn-main {
  color: #fff;
  background-color: #050a09;
  border-color: #050a09;
}
.btn-main:hover {
  color: #fff;
}
.discountPrice {
  font-size: large;
  color: red;
  border: 2px solid red;
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 0 10px;
}
.countdwn {
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}
.countdwn .countdown_msg {
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
  float: left;
  margin: 0;
  letter-spacing: 0.5px;
}
.countdownblock {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #FFF;
  background-color: #FFF;
  border-radius: 2px;
  padding: 0px;
}
.discount_txt {
  background: url(https://prepai.io/wp-content/uploads/2021/11/gif_image.gif);
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pricecrossover {
  color: #dc35459e;
  font-size: medium;
}
.referpage .referralCode {
  width: 70%;
}
.referpage .referralCodeDiv {
  display: flex;
  margin-left: 13%;
}
.btn-Quarterly {
  background-color: #c0c0c0;
  border-color: #c0c0c0;
  color: #fff;
}
.btn-Quarterly:hover,
.btn-Monthly:hover {
  color: #fff;
}
.btn-Yearly {
  background-color: #ffd700;
  border-color: #ffd700;
}
.btn-Monthly {
  background-color: #cd7f32;
  border-color: #cd7f32;
  color: #fff;
}
.btn-Christmas {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #fff;
}

/* step join area */
/*.step_join_area.section_padd {
  padding: 60px 0 75px;
  margin-bottom: 60px;
} */

.main_step_join {
  /* overflow: hidden; */
  position: relative;
  padding: 0px 0;
}

.main_step_join:before {
  content: "";
  width: 3px;
  height: 81%;
  background: #c5cddc;
  position: absolute;
  top: 7%;
  left: 50%;
}

.main_step_join .step_join_box {
  width: 50%;
  clear: both;
  position: relative;
}

.step_join_box .step_join_icon {
  position: absolute;
  top: 120px;
  right: -14px;
  z-index: 1;
}

.step_join_box .step_join_content {
  width: calc(100% - 60px);
  padding: 30px 33px 33px 33px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-shadow: 0px 94px 118px rgba(129, 144, 173, 0.09);
  box-shadow: 0px 94px 118px rgba(129, 144, 173, 0.09);
  border-radius: 12px;
  background: #ffffff;
}

.step_img img {
  margin-bottom: 24px;
}

.step_img img.f-right {
  margin-right: 15px;
  margin-top: 22px;
}

.join_head {
  margin-bottom: 16px;
}

.join_head h4 {
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  letter-spacing: -0.02em;
  display: inline-block;
  color: #2b3d53;
}

.step_join_content p {
  font-weight: normal;
  font-size: 16px;
  color: #4a596a;
}

.free {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  background: #ebfbf7;
  border-radius: 2px;
  color: #30d1a6;
  margin-left: 12px;
  padding: 2px 9px;
  display: inline-block;
}

.step_join_content p {
  font-weight: normal;
  font-size: 16px;
  color: #4a596a;
}

.main_step_join .step_join_box:nth-child(2n),
.main_step_join .step_join_box:nth-child(2n) .step_join_content {
  float: right;
  text-align: left;
}

.main_step_join .step_join_box:nth-child(2n) .step_join_icon {
  position: absolute;
  top: 120px;
  z-index: 1;
  left: -10px;
}

.under {
  text-decoration: underline;
  color: #30d1a6;
}

.under:hover {
  color: #5cdcbb;
  text-decoration: underline;
}
.title_two.v1 {
  margin-top: 30px;
  margin-bottom: 50px;
}
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.upgrade-plan-btn {
  color: #fff;
  background-color: #49c0a0;
  border-color: #49c0a0;
  /* display: inline; */
}

.upgrade-plan-btn-v2 {
  background-color: #fff;
  color: #49c0a0;
  border-color: #49c0a0;
  /* display: inline; */
}

.upgrade-plan-btn-v2:hover {
  background-color: #49c0a0;
  color: white;
}

.golden-tab {
  position: relative;
}
.golden-tab p {
  position: absolute;
  top: 78px;
  text-align: center;
  display: block;
  width: 100%;
  background-color: #fffb1e;
  padding: 10px 0;
  color: red;
  font-weight: 500;
}
.feedbackLabel #labelFeedbackLabel {
  position: absolute;
  top: 30%;
  left: 43%;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
}
.countdwn-home {
  background: url(http://prepai.io/wp-content/uploads/2022/06/Clipboard-June-30-2022-4_10-PM.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: .7rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
  position: relative;
  margin: 0 auto;
  display: block;
  z-index: 1;
  top: 78px;
}
.countdwn-home::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.52);
}
.countdwn-plan {
  background: url(http://prepai.io/wp-content/uploads/2022/06/Clipboard-June-30-2022-4_10-PM.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
  padding-top: .5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
  position: relative;
  margin: 0 auto;
  display: block;
  z-index: 1;
}
.countdwn-plan::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  z-index: -1;
}
.countdwn_msg {
  color: white;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: 10px;
  line-height: normal;
  font-size: 24px;
  text-align: center;
}
.countdown_block_text {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 2px;
  padding: 0px;
}
.runs {
  text-decoration: line-through;
}
.text_color {
  color: red;
}
.link {
  color: yellow !important;
}
.prompt-plan-btn {
  color: #fff;
  background-color: #49c0a0;
  border-color: #49c0a0;
  width: 200px;
}

.prompt-referral-btn {
  color: #fff;
  background-color: #49c0a0;
  border-color: #49c0a0;
}

/* stripe */
.AppWrapper {
  width: 500px;
  margin: 0 auto;
  position: relative;
}
.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #49c0a0;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #8ae2e2;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #49c0a0;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
.SubmitButton {
  display: block;
  font-size: 16px;
  width: 98%;
  height: 40px;
  margin: 25px 0px 15px;
  background-color: #49c0a0 !important;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #49c0a0 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
}

/* New Registration page */
.register-stats-hero {
  text-align: center;
  margin-top: 15%;
  background: #d0f0ec70;
  border-radius: 10px;
  width: 97%;
  padding: 10px 0;
}
.register-stats-hero h2 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 20px;
}
.register-stats-main {
  width: 100%;
  margin: 15px 0;
}
.register-stats-main div {
  width: 33.3333%;
  display: inline-block;
  vertical-align: top;
}
.register-stats-main .stats-number {
  display: block;
  font-size: 20px;
  font-weight: 500;
}

#referclassName {
  width: 100%;
}
#referclassName input {
  width: 95%;
}
